<template>
  <div class="">
    <div
      class="d-flex  align-items-center"
    >
      <b-card
        no-body
        class="col-12 mt-1 p-1"
        style="height: 100%; overflow-x: hidden; min-height: 370px;"
      >
        <div
          v-if="roles.isAdmin"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            class="saj-button mt-1"
            @click="$bvModal.show('new-sub-behaviour')"
          >
            {{ $t('Add New Sub Behavioural') }}
          </b-button>
        </div>
        <div>
          <b-table
            show-empty
            :items="behaviourList"
            :fields="fields"
            responsive=""
            bordered
            class="mt-1 mb-1 saj-subtitle"
          >
            <!-- A custom formatted column -->
            <template #head()="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(index)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.index + 1 }}
              </div>
            </template>
            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>

            <template #cell(subBehaviour)="data">
              <!-- {{ data.item.id }} -->
              {{ isEnglish ? data.item.name_bi : data.item.name_bm }}
            </template>
            <template #cell(action)="data">
              <div
                class="d-flex justify-content-center"
              >
                <div v-if="roles.isAdmin">
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Edit')"
                    icon="EditIcon"
                    size="25"
                    style="color: green"
                    class="mr-1"
                    @click="$bvModal.show('edit-sub-behaviour'), subBehavData = data.item"
                  />
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Delete')"
                    icon="Trash2Icon"
                    class="hoverIcon"
                    style="color: red"
                    size="25"
                    @click="$bvModal.show('delete-sub-behaviour'), SubBehaviourID = data.item.id"
                  />
                </div>
                <div v-else>
                  {{ $t("Not available") }}
                </div>
              </div> </template></b-table>

        </div>
        <b-modal
          id="new-sub-behaviour"
          :hide-header="true"
          :hide-footer="true"
          :centered="true"
          size="lg"
        >
          <subBehaviouralModal
            :is-update="false"
            :behaviour-id="behaviour_id"
            :behaviour-data="{}"
            @close="$bvModal.hide('new-sub-behaviour')"
            @reload-subbehaviour="getSubBehaviour()"
          />
        </b-modal>
        <b-modal
          id="edit-sub-behaviour"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="lg"
        >
          <subBehaviouralModal
            :behaviour-data="subBehavData"
            @close="$bvModal.hide('edit-sub-behaviour')"
            @reload-subbehaviour="getSubBehaviour()"
          />
        </b-modal>
        <b-modal
          id="delete-sub-behaviour"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <deleteSubBehav
            confirm-btn="Yes"
            @cancel="$bvModal.hide('delete-sub-behaviour')"
            @confirm="deleteSubBehavioural()"
          />
        </b-modal>
      </b-card>

    </div>
  </div>
</template>
  <!-- <style scoped>
  .text-style {
      font-weight: 500;
      font-size: 1rem;
      line-height: 17px;
      letter-spacing: 0.15px;
      color: #EEF4F7;
      flex: none;
      order: 0;
      flex-grow: 0;
  }
  </style> -->
<script>
import {
  //   BTabs,
  //   BTab,
  //   VBTooltip,
  BTable,
  VBTooltip,
  BButton,
  BCard,
  //   BModal,
  //   BRow,
  //   BCol,
  //   BButton,
  //   BFormSelect,
  // BFormInput,
} from "bootstrap-vue"
import subBehaviouralModal from "@/component/competency/sub_behaviour_modal.vue"
import deleteSubBehav from "@/pages/alert-modal.vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    subBehaviouralModal,
    deleteSubBehav,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
  },
  data() {
    return {
      behaviour_id: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        { key: 'subBehaviour', label: 'Sub Behavioural', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      behaviourList: [],
      subBehavData: null,
      SubBehaviourID: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles']),
  },
  beforeMount() {
    this.behaviour_id = this.$route.params.id.toString()
  },
  mounted() {
    this.getSubBehaviour()
  },
  methods: {
    getSubBehaviour(){
      this.$axios.get(`${this.$baseUrl}/competency_behaviour/get_subbehaviour_by_behaviour_id?behaviour_id=${this.behaviour_id}`).then(response => {
        this.subBehaviour = response.data.data
        this.behaviourList = this.subBehaviour.map(x => ({
          id: x.id,
          name_bm: x.name_bm,
          name_bi: x.name_bi,
        }))
        // console.log('List subbehaviour', this.behaviourList)
      })
    },
    deleteSubBehavioural(){
      const data = new FormData()
      data.append('sub_behaviour_id', this.SubBehaviourID)
      // data.forEach(x => {
      //   console.log(x)
      // })

      this.$axios.post(`${this.$baseUrl}/competency_behaviour/delete_sub_behaviour_permanently`, data).then(() => {
        this.getSubBehaviour()
        this.$bvModal.hide('delete-sub-behaviour')

        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully deleted')}.`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
  },
}
</script>
